import axios from 'axios';
import {toastr} from 'react-redux-toastr';

import {ENVIAR_EMAIL} from './types';

import {API_EMAIL} from '../consts';

const enviarEmail = async (email) => {
  await axios.post(API_EMAIL, email)
    .then((response) => {
      toastr.success('E-mail enviado com sucesso.');
    })
    .catch((err) => {
      toastr.error('Falha ao enviar e-mail.');
    });
  return {
    type: ENVIAR_EMAIL,
  }
}

export {enviarEmail};
