import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Input, Drawer} from 'antd';
import moment from 'moment';

import Header from '../../header';
import {consultarContratos} from '../../../store/actions/cliente'
import Manuais from './manuais';

class Contratos extends Component {
  state = {
    contrato: null,
    visible: false,
    tamanhoTela: window.innerWidth || 701,
  }

  componentDidMount(){
    const token = JSON.parse(localStorage.getItem('_cliente_token'));
    this.props.consultarContratos(token);
  }

  onHandlerContrato = value => {
    this.setState({
      contrato: this.props.contratos.filter(x => x.id === value),
    })
  }

  onHandlerFecharContrato = () => {
    this.setState({
      contrato: null,
    });
  }

  formatarMoeda = valor => {
    if (valor) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(valor);
    }
  
    return 'R$ 0,00';
  };

  formatarData = data => {
    if (data && data.isValid()) return moment(data).format('DD/MM/YYYY');
  };

  formatarStringData = dataString => {
    if (dataString && moment(dataString).isValid()) {
      return moment(dataString).format('DD/MM/YYYY');
    }
  
    return dataString;
  };

  handlerManuais = () => {
    this.setState({
      visible: true,
    });
  }

  closeDrawer = () => {
    this.setState({
      visible: false,
    })
  }

  render(){
    const {contratos} = this.props;
    const {contrato} = this.state;
    const empreendimentos = contratos.map(x => x.venda.empreendimento.nome).filter(x => x === 'ILHA VERDE');
    const empreendimentosDistintos = [...new Set(empreendimentos)];
    //console.log('empreendimentos', empreendimentos);
    //console.log('empreendimentosDist', empreendimentosDistintos);
    return (
      <div>
        <Header />
        {contrato !== null ?
          <div>
            <Row>
              <Col span={24}>
                <Row justify="center">
                  <Col xl={14} xs={24}>
                    <Row justify="end">
                      <Col span={6}>Codigo NI <Button type="primary" shape="round">{contrato[0].id}</Button></Col>
                    </Row>
                    <Row justify="space-around" align="middle">
                      <Col xs={24} xl={5}>
                        <Row>
                          <Col xs={10} xl={24} className="campos-nome">Empreendimento</Col>
                          <Col xs={14} xl={24}><Input value={contrato[0].venda.empreendimento.nome} /></Col>
                        </Row>
                      </Col>
                      <Col xs={24} xl={2}>
                        <Row>
                          <Col xs={10} xl={24} className="campos-nome">Lote</Col>
                          <Col xs={14} xl={24}><Input value={contrato[0].numero} /></Col>
                        </Row>
                      </Col>
                      <Col xs={24} xl={4}>
                        <Row>
                          <Col xs={10} xl={24} className="campos-nome">Metragem (m²)</Col>
                          <Col xs={14} xl={24}><Input value={contrato[0].venda.unidade.metragem} /></Col>
                        </Row>
                      </Col>
                      <Col xs={24} xl={3}>
                        <Row>
                          <Col xs={10} xl={24} className="campos-nome">Matrícula</Col>
                          <Col xs={14} xl={24}><Input value={contrato[0].matricula} /></Col>
                        </Row>
                      </Col>
                      <Col xs={24} xl={3}>
                        <Row>
                          <Col xs={10} xl={24} className="campos-nome">Situação</Col>
                          <Col xs={14} xl={24}><Input value={contrato[0].situacao} /></Col>
                        </Row>
                      </Col>
                      <Col xs={24} xl={4}>
                        <Row>
                          <Col xs={10} xl={24} className="campos-nome">Valor (R$)</Col>
                          <Col xs={14} xl={24}><Input value={this.formatarMoeda(contrato[0].total)} /></Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={20}>
                        <Row justify="space-around" align="middle">
                          <Col span={4} className="campos-nome" style={{textAlign: "center"}}>Parcela</Col>
                          <Col span={10} className="campos-nome" style={{textAlign: "center"}}>Valor (R$)</Col>
                          <Col span={10} className="campos-nome" style={{textAlign: "center"}}>Vencimento</Col>
                        </Row>
                        {contrato[0].parcelas.map((x, i) => (
                          <Row justify="space-around" align="middle">
                            <Col span={4} style={{textAlign: "center"}}>{i+1}</Col>
                            <Col span={10} style={{textAlign: "center"}}>{this.formatarMoeda(x.total)}</Col>
                            <Col span={10} style={{textAlign: "center"}}>{this.formatarStringData(x.dataVencimento)}</Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xl={2} xs={8}>
                    <Button type="primary" shape="round" block onClick={() => this.onHandlerFecharContrato()}>Voltar</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          :
          <div>
            <Row>
              <Col xl={24} xs={24}>
                <Row justify="center">
                  <Col span={10}>
                    {contratos.map(x => (
                      <Button type="primary" onClick={() => this.onHandlerContrato(x.id)}>{x.id}</Button>
                    ))}                
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={8}></Col>
              <Col span={8}>
                {contratos !== null &&
                  <Row>
                    <Col span={24}>
                      <div className="botao">
                        <Button type="primary" shape="round" block onClick={() => this.handlerManuais()} >MANUAIS</Button>
                      </div>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col span={24}>
                    <div className="botao">
                      <Link to="/">
                        <Button type="primary" shape="round" block>INÍCIO</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Drawer
              width={this.state.tamanhoTela > 700 ? "30%" : "100%"}
              titulo="Manuais"
              placement="right"
              visible={this.state.visible}
              onClose={this.closeDrawer}
            >
              <Manuais empreendimentos={empreendimentosDistintos}/>
            </Drawer>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({contratos: state.clienteReducer.contratos})

const mapDispatchToProps = (dispatch) => {
  return {
    consultarContratos: (token) => dispatch(consultarContratos(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contratos);
