import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Drawer, Input, Tooltip } from 'antd';

import Header from '../../header';

import { enviarEmail } from '../../../store/actions/envioEmail';

import './index.css';

const duvidas = [
  {
    codigo: '1.1',
    descricao: 'Esqueci de pagar o boleto e agora esta vencido como faço para pagar?',
    email: 'rejane@novaipanema.net',
    texto: 2,
  },
  {
    codigo: '1.2',
    descricao: 'Não recebi meu boleto para pagamento da parcela como faço para pagar?',
    email: 'rmotam@gmail.com',
    texto: 1,
  },
  {
    codigo: '2.1',
    descricao: 'Como faço para antecipar a quitação do meu contrato?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '2.2',
    descricao: 'Caso eu queira antecipar o pagamento das parcelas como faço?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '2.3',
    descricao: 'Como faço para saber meu saldo devedor?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },  
  {
    codigo: '3.1',
    descricao: 'Como faço para acessar a administradora da associação do meu condomínio?',
    email: 'priscila@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '3.2',
    descricao: 'Posso vender meu lote e transferir a dívida para quem comprar?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '3.3',
    descricao: 'Quitei meu lote como faço para transferir para meu nome?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },  
  {
    codigo: '4.1',
    descricao: 'Como faço para iniciar a construções no meu lote?',
    email: 'priscila@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '5.1',
    descricao: 'Como faço para receber uma cópia do meu contrato de compra?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '5.2',
    descricao: 'Como faço para conseguir meu informe de imposto de renda?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '5.3',
    descricao: 'Sua dúvida não está aqui? Envie para nós',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },  
  {
    codigo: '6.1',
    descricao: 'A autorização de construção é liberada somente quando a Nova Ipanema conclui a obra e faz a entrega do Empreendimento para o Município.',
  },  
  {
    codigo: '7.1',
    descricao: 'Tenho parcelas vencidas como faço para quita-las?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '7.2',
    descricao: 'Como faço para saber os débitos de IPTU do meu terreno?',
  },
  {
    codigo: '7.3',
    descricao: 'Como faço para emitir a guia de IPTU?',
    email: 'rejane@novaipanema.net',
    texto: 1,
  },
  {
    codigo: '7.4',
    descricao: 'O lote não poderá ter débitos de parcelas de financiamento ou débitos de IPTU.',
  },
]

const corpoEmail = (corpo) => {
  const tabela = `<table>
                    <tr>
                      <td>Usuário</td>
                      <td>${corpo.usuario}</td>
                    </tr>
                    <tr>
                      <td>Lote</td>
                      <td>${corpo.lote}</td>
                    </tr>
                    <tr>
                      <td>Quadra</td>
                      <td>${corpo.quadra}</td>
                    </tr>
                    <tr>
                      <td>Empreendimento</td>
                      <td>${corpo.empreendimento}</td>
                    </tr>
                    <tr>
                      <td>Solicitação</td>
                      <td>${corpo.solicitacao}</td>
                    </tr>
                  </table>`;
  return tabela;
};
class Duvidas extends Component {

  state = {
    titulo: '',
    visible: false,
    lote: '',
    quadra: '',
    empreendimento: '',
    data: '',
    texto: {},
    solicitacao: '',
    email: '',
    tamanhoTela: window.innerWidth || 701,
  }

  texto1 = <Col>Solicite aqui informando lote <Input width={20} onChange={(e) => this.onChangeLote(e.target.value)} />, quadra <Input onChange={(e) => this.onChangeQuadra(e.target.value)} />, empreendimento <Input onChange={(e) => this.onChangeEmpreendimento(e.target.value)} />. Após preencher os campos <a onClick={() => this.enviarSolicitacao()}>clique aqui.</a></Col>
  texto2 = <Col>Se tiver dentro dos 30 dias é possível pagar o boleto enviado, mas se ultrapassou, informe lote <Input width={20} onChange={(e) => this.onChangeLote(e.target.value)} />, quadra <Input onChange={(e) => this.onChangeQuadra(e.target.value)} />, empreendimento <Input onChange={(e) => this.onChangeEmpreendimento(e.target.value)} /> e data <Input onChange={(e) => this.onChangeData(e.target.value)} /> que pretende pagar. Após preencher os campos <a onClick={() => this.enviarSolicitacao()}>clique aqui.</a></Col>

  showDrawer = param => {
    this.setState({
      texto: param.texto === 2 ? this.texto2 : this.texto1,
      solicitacao: param.descricao,
      email: param.email,
      visible: true,
    })
  }

  enviarSolicitacao = () => {
    if (this.state.lote === '' || this.state.quadra === '' || this.state.empreendimento === '') {
      toastr.error('Todos os campos devem ser preenchidos.');
      return;
    }
    const corpo = {
      lote: this.state.lote,
      quadra: this.state.quadra,
      empreendimento: this.state.empreendimento,
      solicitacao: this.state.solicitacao,
      usuario: this.props.user.usuario,
    }
    const bodyText = corpoEmail(corpo);
    const email = {
      mailTo: this.state.email,
      subject: 'Dúvidas Frequentes',
      bodyText,
    }
    this.props.enviarEmail(email);
    toastr.success('E-mail enviado com sucesso.');

    this.closeDrawer();
  }

  closeDrawer = () => {
    this.setState({
      visible: false,
    })
  }

  onChangeLote = value => {
    this.setState({
      lote: value,
    })
  }

  onChangeQuadra = value => {
    this.setState({
      quadra: value,
    })
  }

  onChangeData = value => {
    this.setState({
      data: value,
    })
  }

  onChangeEmpreendimento = value => {
    this.setState({
      empreendimento: value,
    })
  }

  render() {
    return (
      <div>
        <Header />
        <Row>
          <Col span={4}></Col>
          <Col xl={16} xs={24}>
            <h2 className="titulo-h2">DÚVIDAS FREQUENTES</h2>
          </Col>
          <Col span={4}></Col>
        </Row>
        <Row align="middle">
          <Col xl={2} xs={0}></Col>
          <Col xl={1} xs={2}>
            <img id="pagamento" alt="" src="/images/icones/01.jpg" />
          </Col>
          <Col xl={19} xs={22}>
            <h3 className="titulo-h3">1. Pagamentos</h3>
          </Col>
          <Col xl={2} xs={0}></Col>
        </Row>
        <Row align="middle">
          <Col span={3}></Col>
          <Col span={19}>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[0])}>{duvidas[0].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[1])}>{duvidas[1].descricao}</Button>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row align="middle">
          <Col xl={2} xs={0}></Col>
          <Col xl={1} xs={2}>
            <img id="pagamento" alt="" src="/images/icones/02.jpg" />
          </Col>
          <Col xl={19} xs={22}>
            <h3 className="titulo-h3">2. Quitação ou Antecipação</h3>
          </Col>
          <Col xl={2} xs={0}></Col>
        </Row>
        <Row align="middle">
          <Col span={3}></Col>
          <Col span={19}>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[2])}>{duvidas[2].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[3])}>{duvidas[3].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[4])}>{duvidas[4].descricao}</Button>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row align="middle">
          <Col xl={2} xs={0}></Col>
          <Col xl={1} xs={2}>
            <img id="pagamento" alt="" src="/images/icones/03.jpg" />
          </Col>
          <Col xl={19} xs={22}>
            <h3 className="titulo-h3">3. Transferência - Venda Escritura</h3>
          </Col>
          <Col xl={2} xs={0}></Col>
        </Row>
        <Row align="middle">
          <Col span={3}></Col>
          <Col span={19}>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[5])}>{duvidas[5].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[6])}>{duvidas[6].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[7])}>{duvidas[7].descricao}</Button>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row align="middle">
          <Col xl={2} xs={0}></Col>
          <Col xl={1} xs={2}>
            <img id="pagamento" alt="" src="/images/icones/04.jpg" />
          </Col>
          <Col xl={19} xs={22}>
            <h3 className="titulo-h3">4. Solicite sua Autorização de Construção</h3>
          </Col>
          <Col xl={2} xs={0}></Col>
        </Row>
        <Row align="middle">
          <Col span={3}></Col>
          <Col span={19}>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[8])}>{duvidas[8].descricao}</Button>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row align="middle">
          <Col xl={2} xs={0}></Col>
          <Col xl={1} xs={2}>
            <img id="pagamento" alt="" src="/images/icones/05.jpg" />
          </Col>
          <Col xl={19} xs={22}>
            <h3 className="titulo-h3">5. Perguntas Frequentes</h3>
          </Col>
          <Col xl={2} xs={0}></Col>
        </Row>
        <Row align="middle">
          <Col span={3}></Col>
          <Col span={19}>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[9])}>{duvidas[9].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
              <Button onClick={() => this.showDrawer(duvidas[10])}>{duvidas[10].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
              <Button onClick={() => this.showDrawer(duvidas[11])}>{duvidas[11].descricao}</Button>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row align="middle">
          <Col xl={2} xs={0}></Col>
          <Col xl={1} xs={2}>
            <img id="pagamento" alt="" src="/images/icones/06.jpg" />
          </Col>
          <Col xl={19} xs={22}>
            <h3 className="titulo-h3">6. Quando Posso Começar a Construir</h3>
          </Col>
          <Col xl={2} xs={0}></Col>
        </Row>
        <Row align="middle">
          <Col span={3}></Col>
          <Col span={19}>
            <Row>
              <Col span={24}>
                <Tooltip title="Mandaremos um e-mail informando.">
                  <Button>{duvidas[12].descricao}</Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row align="middle">
          <Col xl={2} xs={0}></Col>
          <Col xl={1} xs={2}>
            <img id="pagamento" alt="" src="/images/icones/07.jpg" />
          </Col>
          <Col xl={19} xs={22}>
            <h3 className="titulo-h3">7. Dívida</h3>
          </Col>
          <Col xl={2} xs={0}></Col>
        </Row>
        <Row align="middle">
          <Col span={3}></Col>
          <Col span={19}>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[13])}>{duvidas[13].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[14])}>{duvidas[14].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button onClick={() => this.showDrawer(duvidas[15])}>{duvidas[15].descricao}</Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tooltip title="Informativo">
                  <Button>{duvidas[16].descricao}</Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row>
          <Col span={8}></Col>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <div className="botao">
                  <Link to="/">
                    <Button type="primary" shape="round" block>INÍCIO</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Drawer
          width={this.state.tamanhoTela > 700 ? "20%" : "100%"}
          titulo={this.state.titulo}
          placement="right"
          visible={this.state.visible}
          onClose={this.closeDrawer}
        >
          <Row>
            {this.state.texto}
          </Row>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => ({ user: state.authReducer.user })

const mapDispatchToProps = (dispatch) => {
  return {
    enviarEmail: (email) => dispatch(enviarEmail(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Duvidas);
