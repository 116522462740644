import {CLIENTE_CONSULTAR_CADASTRO, CLIENTE_CONSULTAR_CONTRATOS, CLIENTE_CONSULTAR_BOLETOS} from '../../actions/cliente/types';

const INITIAL_STATE = {
  cadastro: {},
  contratos: [],
  boletos: [],
}

const clienteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLIENTE_CONSULTAR_CADASTRO:
      return { ...state, cadastro: action.payload }
    case CLIENTE_CONSULTAR_CONTRATOS:
      return { ...state, contratos: action.payload }
    case CLIENTE_CONSULTAR_BOLETOS:
      return { ...state, boletos: action.payload }
    default:
      return state;
  }
}

export default clienteReducer;
