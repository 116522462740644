import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {Link} from 'react-router-dom';
import { Row, Col, Button, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import Header from '../header';
import { logar } from '../../store/actions/auth';

import './index.css';

class Login extends Component {
  state = {
    login: '',
    senha: '',
  }

  onChangeLogin = (e) => {
    this.setState({
      login: e.target.value,
    });
  };

  onChangePassword = (e) => {
    this.setState({
      senha: e.target.value,
    });
  };

  onHandlerLogin = async () => {
    const login = {
      usuario: this.state.login,
      senha: this.state.senha,
    }
    try {
      await this.props.logar(login);
    } catch (e) {
      toastr.error('Login e/ou senha inválida.');
    }
  }

  render() {
    return (
      <div id="tela-login">
        <Header login={true} />
        <Row>
          <Col span={7}></Col>
          <Col xl={10} xs={24}>
            <Row className="eixo-tela-login">
              <Col span={24}>
                <div>
                  No portal do Cliente você terá acesso exclusivo às informações financeiras do seu contrato, boleto bancário do mês de forma prática, transparente e com toda a comodidade possível.
                </div>
              </Col>
            </Row>
            <Row className="eixo-tela-login" justify='center'>
              <Col span={18}>
                <Row>
                  <Col>
                    <h3 className="titulo-h3">Informe seus dados para acessar:</h3>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col className="texto-nome-input">
                        <div>E-Mail:</div>
                      </Col>
                      <Col span={20}>
                        <Input placeholder="informe e-mail" className="input-login" onChange={this.onChangeLogin} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col className="texto-nome-input">
                        <div>Senha:</div>
                      </Col>
                      <Col span={20}>
                        <Input.Password
                          placeholder="informe senha"
                          onChange={this.onChangePassword}
                          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={8}>
                    <div className="botao">
                      <Button
                        type="primary"
                        shape="round"
                        block
                        onClick={() => this.onHandlerLogin()}
                      >Entrar
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row className="eixo-tela-login">
                  <Col span={12}>                    
                    <Link to="/primeiro-acesso">
                      <Button type="link">Primeiro acesso</Button>
                    </Link>
                  </Col>
                  <Col span={12}>                    
                    <Link to="/reset">
                      <Button type="link">Esqueci a senha</Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>            
          </Col>
          <Col span={7}></Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({ user: state.authReducer.user })

const mapDispatchToProps = (dispatch) => {
  return {
    logar: (login) => dispatch(logar(login)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
