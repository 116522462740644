import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

import Header from '../header';
import { logout } from '../../store/actions/auth';

import './index.css';

const Cliente = (props) => {
  return (
    <div>
      <Header />
      <Row justify='center'>
        <Col span={8}></Col>
        <Col xl={8} xs={24}>
          <Row justify='center'>
            <Col span={8}>
              <h3 className="titulo-h3">Dados Cadastrais</h3>
            </Col>
            <Col span={6}>
              <Link to="/cadastro">
                <Button type="primary" shape="round" block>Entrar</Button>
              </Link>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}>
              <h3 className="titulo-h3">Contratos</h3>
            </Col>
            <Col span={6}>
              <Link to="/contratos">
                <Button type="primary" shape="round" block>Entrar</Button>
              </Link>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}>
              <h3 className="titulo-h3">Boletos</h3>
            </Col>
            <Col span={6}>
              <Link to="/boletos">
                <Button type="primary" shape="round" block>Entrar</Button>
              </Link>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}>
              <h3 className="titulo-h3">Dúvidas Frequentes</h3>
            </Col>
            <Col span={6}>
              <Link to="/duvidas">
                <Button type="primary" shape="round" block>Entrar</Button>
              </Link>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}></Col>
            <Col span={6}>
              <div className="botao">
                <Button type="primary" shape="round" block onClick={() => props.logout()}>Sair</Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={8}></Col>
      </Row>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Cliente);
