import React from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

import PublicRouter from './routes/public';
import ProtectedRoutes from './routes/protected';
import Mensagens from './views/mensagens';
import './App.css';

const App = (props) => {
  return (
    <Router>
      <Mensagens />
      {props.user && props.user.token ?
        <ProtectedRoutes /> : <PublicRouter />      
      }      
    </Router>
  );
}

const mapStateToProps = state => ({user: state.authReducer.user})

export default connect(mapStateToProps)(App);
