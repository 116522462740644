import React from 'react';
import {Row, Col, Button} from 'antd';
import { Link } from 'react-router-dom';

import './index.css';

const Manuais = (props) => {
  return (
    <Row>
      {props.empreendimentos.map(x => (
        <div>
          <Row>
            <Col><b>{x}</b></Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/ilha-verde/area-preservacao-permanente.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Área Preservação Permanente</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/ilha-verde/convencao.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Convenção</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/ilha-verde/manual-condominial.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Manual Condominial</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/ilha-verde/unidade-autonoma-residencial.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Unidade Autonoma Residencial</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/ilha-verde/manual-operacional-piscinas.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Manual Operacional Piscinas</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24}>
              <Link to="/downloads/ilha-verde/lista-fornecedores.pdf" download target="_blank">
                <Button type="primary" shape="round" block>Lista Fornecedores</Button>
              </Link>
            </Col>
          </Row>
        </div>
      ))}
    </Row>
  )
}

export default Manuais;
