import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Input, Drawer} from 'antd';
import moment from 'moment';

import Header from '../../header';
import {consultarBoletos} from '../../../store/actions/cliente'

class Boletos extends Component {

  componentDidMount(){
    const token = JSON.parse(localStorage.getItem('_cliente_token'));
    this.props.consultarBoletos(token);
  }

  onHandlerFecharContrato = () => {
    this.setState({
      contrato: null,
    });
  }

  formatarMoeda = valor => {
    if (valor) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(valor);
    }
  
    return 'R$ 0,00';
  };

  formatarData = data => {
    if (data && data.isValid()) return moment(data).format('DD/MM/YYYY');
  };

  formatarStringData = dataString => {
    if (dataString && moment(dataString).isValid()) {
      return moment(dataString).format('DD/MM/YYYY');
    }
  
    return dataString;
  };

  render(){
    const {boletos} = this.props;
    return (
      <div>
        <Header />
        <div>
          <Row>
            <Col span={8}></Col>
            <Col span={8}>
              {boletos.map(x => (
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col>Empreendimento:</Col>
                      <Col>{x.empreendimento}</Col>
                    </Row>
                    <Row>
                      <Col>Contrato:</Col>
                      <Col>{x.contratoNumero}</Col>
                    </Row>
                    <Row>
                      <Col>Unidade:</Col>
                      <Col>{x.unidade}</Col>
                    </Row>
                    <Row>
                      <Col>Parcela:</Col>
                      <Col>{x.parela}</Col>
                    </Row>
                    <Row>
                      <Col>Vencimento:</Col>
                      <Col>{this.formatarStringData(x.vencimento)}</Col>
                    </Row>
                    <Row>
                      <Col>Valor</Col>
                      <Col>{this.formatarMoeda(x.valor)}</Col>
                    </Row>
                    <Row>
                      <Col>
                        <a href={x.link} target="_blank" rel="noreferrer">Gerar</a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={24}>
                  <div className="botao">
                    <Link to="/">
                      <Button type="primary" shape="round" block>INÍCIO</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={8}></Col>
          </Row>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({boletos: state.clienteReducer.boletos})

const mapDispatchToProps = (dispatch) => {
  return {
    consultarBoletos: (token) => dispatch(consultarBoletos(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Boletos);
