import React from 'react';
import {Row, Col} from 'antd';

const Header = (props) => {
  return (
    <Row>
      <Col span={24}>
        {props.login ?
          <img id="header" alt="" src="/images/header/header.png" width="100%" />
        : 
          <img id="header" alt="" src="/images/header/portal-cliente-header.jpg" width="100%" />}
      </Col>
    </Row>
  )
}

export default Header;
