import axios from 'axios';

import {CLIENTE_CONSULTAR_CADASTRO, CLIENTE_CONSULTAR_CONTRATOS, CLIENTE_CONSULTAR_BOLETOS} from './types';

import {API_CLIENTE} from '../consts';

const consultarCadastro = async (token) => {
  const retorno = await axios.get(`${API_CLIENTE}/ConsultarCadastro?token=${token}`)
  return {
    type: CLIENTE_CONSULTAR_CADASTRO,
    payload: retorno.data,
  }
}

const consultarContratos = async (token) => {
  const retorno = await axios.get(`${API_CLIENTE}/ConsultarContratos?token=${token}`)
  return {
    type: CLIENTE_CONSULTAR_CONTRATOS,
    payload: retorno.data,
  }
}

const consultarBoletos = async (token) => {
  const retorno = await axios.get(`${API_CLIENTE}/ConsultarBoletos?token=${token}`)
  return {
    type: CLIENTE_CONSULTAR_BOLETOS,
    payload: retorno.data,
  }
}

export {consultarCadastro, consultarContratos, consultarBoletos};
