import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Row, Col, Button, Input } from 'antd';

import Header from '../header/registro';
import { reset, alterarSenha } from '../../store/actions/auth';

import './index.css';

class Reset extends Component {
  state = {
    email: '',
    codigo: '',
    senha1: '',
    senha2: '',
    retorno: false,
  }

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  onChangeCodigo = (e) => {
    this.setState({
      codigo: e.target.value,
    });
  };

  onChangeSenha1 = (e) => {
    this.setState({
      senha1: e.target.value,
    });
  };

  onChangeSenha2 = (e) => {
    this.setState({
      senha2: e.target.value,
    });
  };

  onHandlerEnviar = async () => {
    if (this.state.email === ''){
      toastr.error('É necessário informar um e-mail válido.');
      return;
    }
    try {
      const reset = await this.props.reset(this.state.email);
      this.setState({retorno: reset.payload});
    } catch (e) {
      toastr.error('Falha ao tentar recuperar senha.');
    }
  }

  onHandlerValidar = async () => {
    const {codigo, senha1, senha2} = this.state;
    if (senha1.length < 6){
      toastr.error('Senha deve contar 6 ou mais caracteres.');
      return;
    }
    if (senha1 !== senha2){
      toastr.error('Senhas devem ser iguais.');
      return;
    }
    const objAlterar = {
      codigo,
      senha1,
      senha2
    }
    try {
      await this.props.alterarSenha(objAlterar);
      toastr.success('Senha alterada com sucesso.');
      this.props.history.push('/');
    } catch (e) {
      toastr.error('Falha ao tentar recuperar senha.');
    }
  }

  render() {
    return (
      <div id="tela-login">
        <Header login={true} />
        <Row>
          <Col span={7}></Col>
          <Col span={10}>
            <Row className="eixo-tela-login" justify='center'>
              <Col span={18}>
                {!this.state.retorno ?
                  <React.Fragment>
                    <Row>
                      <Col>
                        <h3 className="titulo-h3">Informe seu e-mail cadastrado para recuperar a senha e clique em enviar:</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input">
                            <div>E-Mail:</div>
                          </Col>
                          <Col span={20}>
                            <Input placeholder="informe e-mail" className="input-login" onChange={this.onChangeEmail} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={8}>
                        <div className="botao">
                          <Button
                            type="primary"
                            shape="round"
                            block
                            onClick={() => this.onHandlerEnviar()}
                          >Enviar
                          </Button>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="botao">
                          <Link to="/">
                            <Button
                              type="primary"
                              shape="round"
                              block
                            >Cancelar
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <Row>
                      <Col>
                        <h3 className="titulo-h3">Informe código recebido por e-mail e clique em validar:</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={4}>
                            <div>Código:</div>
                          </Col>
                          <Col span={16}>
                            <Input placeholder="informe código" className="input-login" onChange={this.onChangeCodigo} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={4}>
                            <div>Senha:</div>
                          </Col>
                          <Col span={16}>
                            <Input.Password placeholder="Senha" className="input-login" onChange={this.onChangeSenha1} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={4}>
                            <div>Repetir Senha:</div>
                          </Col>
                          <Col span={16}>
                            <Input.Password placeholder="Senha" className="input-login" onChange={this.onChangeSenha2} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={8}>
                        <div className="botao">
                          <Button
                            type="primary"
                            shape="round"
                            block
                            onClick={() => this.onHandlerValidar()}
                          >Validar
                          </Button>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="botao">
                          <Link to="/">
                            <Button
                              type="primary"
                              shape="round"
                              block
                            >Cancelar
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                }
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                      Se você não recebeu um email com instruções para recuperar a senha, envie um e-mail para: relacionamento@novaipanema.net ou entre em contato pelo telefone: (51) 3246.1015
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={7}></Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({ user: state.authReducer.user })

const mapDispatchToProps = (dispatch) => {
  return {
    reset: (email) => dispatch(reset(email)),
    alterarSenha: (objAlterar) => dispatch(alterarSenha(objAlterar)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
