import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import reducers from './reducers';

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const storeConfig = () => {
  const middlewares = [thunk, promise];
  return applyMiddleware(...middlewares)(createStore)(reducers, devTools);
}

export default storeConfig;