import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Cliente from '../../views/cliente';
import Cadastro from '../../views/cliente/cadastro';
import Contratos from '../../views/cliente/contratos';
import Boletos from '../../views/cliente/boletos';
import Duvidas from '../../views/cliente/duvidas';

const ProtectedRoutes = (props) => {
  return (
    <Switch>
      <Route exact path="/cadastro" component={Cadastro} />
      <Route exact path="/contratos" component={Contratos} />
      <Route exact path="/boletos" component={Boletos} />
      <Route exact path="/duvidas" component={Duvidas} />
      <Route exact path="/" component={Cliente} />
    </Switch>
  )
};

export default ProtectedRoutes;
