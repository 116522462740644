import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Login from '../../views/login';
import Reset from '../../views/reset';
import Registro from '../../views/registro';

const PublicRoutes = (props) => {
  return (
    <Switch>
      <Route exact path="/reset" component={Reset} />
      <Route exact path="/primeiro-acesso" component={Registro} />
      <Route exact path="/" component={Login} />
    </Switch>
  )
};

export default PublicRoutes;