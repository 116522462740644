import axios from 'axios';

import {USER_FETCHED, USER_LOGOUT} from './types';

import {API_AUTH} from '../consts';

const logar = async (login) => {
  const retorno = await axios.post(API_AUTH + '/Logar', login);
  delete retorno.data.senha;
  return (
    {
      type: USER_FETCHED,
      payload: retorno.data,
    }
  )
}

const logout = async () => {
  return ({
    type: USER_LOGOUT,
  })
}

const reset = async (email) => {
  const retorno = await axios.post(API_AUTH + '/Reset', { usuario: email });
  return ({
    type: '',
    payload: retorno.data,
  })
}

const alterarSenha = async (objAlterar) => {
  const retorno = await axios.post(API_AUTH + '/AlterarSenha', objAlterar);
  return ({
    type: '',
    payload: retorno.data,
  }) 
}

const registro = async (dados) => {
  const retorno = await axios.post(API_AUTH + '/RegistroCliente', dados);
  return ({
    type: '',
    payload: retorno.data,
  });
}

const ativar = async (codigo) => {
  const retorno = await axios.post(API_AUTH + '/AtivacaoCliente?codigo=' + codigo);
  return ({
    type: '',
    payload: retorno.data,
  });
}

export {logar, logout, reset, alterarSenha, registro, ativar};