import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Row, Col, Button, Input } from 'antd';

import Header from '../header/registro';
import { registro, ativar } from '../../store/actions/auth';

import './index.css';

class Registro extends Component {
  state = {
    email: '',
    registro: '',
    whatsApp: '',
    senha: '',
    senha2: '',
    codigo: '',
    retorno: false,
  }

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  onChangeCpf = (e) => {
    this.setState({
      registro: e.target.value,
    });
  };

  onChangeWhatsApp = (e) => {
    this.setState({
      whatsApp: e.target.value,
    });
  };

  onChangeSenha1 = (e) => {
    this.setState({
      senha: e.target.value,
    });
  };

  onChangeSenha2 = (e) => {
    this.setState({
      senha2: e.target.value,
    });
  };

  onChangeCodigo = (e) => {
    this.setState({
      codigo: e.target.value,
    });
  };

  onHandlerRegistro = async () => {
    const {registro, email, senha, senha2, whatsApp} = this.state;

    if (senha.length < 6){
      toastr.error('Senha deve contar 6 ou mais caracteres.');
      return;
    }
    if (senha !== senha2){
      toastr.error('Senhas devem ser iguais.');
      return;
    }

    const dados = {
      registro,
      email,
      senha,
      whatsApp
    };
    try {
      await this.props.registro(dados);
      toastr.success('Registro OK, verifique seu email/whatsApp');
      this.setState({retorno: true});
    } catch (e) {
      toastr.error('Falha ao registrar dados.');
      console.log('erro:', e);
    }
  }

  onHandlerValidar = async () => {
    const {codigo} = this.state;

    if (codigo === ''){
      toastr.error('Código não pode ser vazio.');
      return;
    }

    try {
      await this.props.ativar(codigo);
      toastr.success('Cadastro ativado com sucesso.');
      this.props.history.push('/');
    } catch (e) {
      toastr.error('Falha ao ativar cadastro.');
    }
  }

  render() {
    return (
      <div id="tela-login">
        <Header login={true} />
        <Row>
          <Col span={7}></Col>
          <Col span={10}>
            <Row className="eixo-tela-login" justify='center'>
              <Col span={18}>
                {!this.state.retorno ?
                  <React.Fragment>
                    <Row>
                      <Col>
                        <h3 className="titulo-h3">Registro para novos usuários, todos os campos são obrigatórios</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={6}>
                            <div>CPF/CNPJ:</div>
                          </Col>
                          <Col span={16}>
                            <Input placeholder="informe cpf ou cnpj" className="input-login" onChange={this.onChangeCpf} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={6}>
                            <div>E-Mail:</div>
                          </Col>
                          <Col span={16}>
                            <Input placeholder="informe e-mail" className="input-login" onChange={this.onChangeEmail} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={6}>
                            <div>WhatsApp:</div>
                          </Col>
                          <Col span={16}>
                            <Input placeholder="informe whatsapp" className="input-login" onChange={this.onChangeWhatsApp} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={6}>
                            <div>Senha:</div>
                          </Col>
                          <Col span={16}>
                            <Input.Password placeholder="Senha" className="input-login" onChange={this.onChangeSenha1} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={6}>
                            <div>Repetir Senha:</div>
                          </Col>
                          <Col span={16}>
                            <Input.Password placeholder="Senha" className="input-login" onChange={this.onChangeSenha2} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={8}>
                        <div className="botao">
                          <Button
                            type="primary"
                            shape="round"
                            block
                            onClick={() => this.onHandlerRegistro()}
                          >Enviar
                          </Button>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="botao">
                          <Link to="/">
                            <Button
                              type="primary"
                              shape="round"
                              block
                            >Cancelar
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                          Se você não recebeu um email com instruções para recuperar a senha, envie um e-mail para: relacionamento@novaipanema.net ou entre em contato pelo telefone: (51) 3246.1015
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <Row>
                      <Col>
                        <h3 className="titulo-h3">Informe código recebido por e-mail e clique em validar:</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col className="texto-nome-input" span={4}>
                            <div>Código:</div>
                          </Col>
                          <Col span={16}>
                            <Input placeholder="informe código" className="input-login" onChange={this.onChangeCodigo} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={8}>
                        <div className="botao">
                          <Button
                            type="primary"
                            shape="round"
                            block
                            onClick={() => this.onHandlerValidar()}
                          >Validar
                          </Button>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="botao">
                          <Link to="/">
                            <Button
                              type="primary"
                              shape="round"
                              block
                            >Cancelar
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                }
              </Col>
            </Row>
          </Col>
          <Col span={7}></Col>
        </Row>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    registro: (dados) => dispatch(registro(dados)),
    ativar: (codigo) => dispatch(ativar(codigo)),
  };
};

export default connect(null, mapDispatchToProps)(Registro);
