// const API_LOGIN = 'http://bsc01.myq-see.com:8081/api/BaseApi/Logon';
// const API_LOGIN = 'https://envioemail.gruponovaipanema.com.br/auth/Logar';
// const API_EMAIL = 'https://envioemail.gruponovaipanema.com.br/email/envioemail';
// const API_CLIENTE = 'https://envioemail.gruponovaipanema.com.br/cliente';
// const API_AUTH = 'https://envioemail.gruponovaipanema.com.br/auth';
//const API_CLIENTE = 'http://localhost:5000/cliente';
//const API_AUTH = 'https://localhost:5001/auth';
const API_LOGIN = 'https://apiemail.gruponovaipanema.com.br/auth/Logar';
const API_EMAIL = 'https://apiemail.gruponovaipanema.com.br/email/envioemail';
const API_CLIENTE = 'https://apiemail.gruponovaipanema.com.br/cliente';
const API_AUTH = 'https://apiemail.gruponovaipanema.com.br/auth';

export {API_LOGIN, API_EMAIL, API_CLIENTE, API_AUTH}
