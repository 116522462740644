import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Input} from 'antd';

import Header from '../../header';
import {consultarCadastro} from '../../../store/actions/cliente'

import './index.css';
class Cadastro extends Component {

  async componentDidMount(){
    const token = JSON.parse(localStorage.getItem('_cliente_token'));
    await this.props.consultarCadastro(token);
  }

  render(){
    const {cadastro} = this.props;
    return (
      <div>
        <Header />
        {cadastro && cadastro.enderecos && (
          <Row>
            <Col span={7}></Col>
            <Col xl={10} xs={24}>
              <Row>
                <Col span={10} className="campos-nome">Cliente:</Col>
                <Col span={12}><Input value={`Pessoa ${cadastro.tipo}`} /></Col>
              </Row>
              <Row>
                <Col span={10} className="campos-nome">Nome:</Col>
                <Col span={12}><Input value={cadastro.nome} /></Col>
              </Row>
              <Row>
                <Col span={10} className="campos-nome">CPF:</Col>
                <Col span={12}><Input value={cadastro.cpfCnpj} /></Col>
              </Row>
              {cadastro.enderecos.map(x => (
                <React.Fragment>
                  <Row>
                    <Col span={10} className="campos-nome">Endereço {x.tipo}:</Col>
                    <Col span={12}><Input value={`${x.logradouro}, ${x.numero}`} /></Col>
                  </Row>
                  <Row>
                    <Col span={10} className="campos-nome">Bairro / CEP</Col>
                    <Col span={6}><Input value={x.bairro} /></Col>
                    <Col span={6}><Input value={x.cep} /></Col>
                  </Row>
                  <Row>
                    <Col span={10} className="campos-nome">Cidade / UF</Col>
                    <Col span={6}><Input value={x.municipio} /></Col>
                    <Col span={6}><Input value={x.uf} /></Col>
                  </Row>
                </React.Fragment>
              ))}
              {cadastro.telefones.map(x => (
                <React.Fragment>
                  <Row>
                    <Col span={10} className="campos-nome">Telefone {x.tipo}:</Col>
                    <Col span={12}><Input value={`${x.ddd} ${x.numero}`} /></Col>
                  </Row>
                </React.Fragment>
              ))}
              {cadastro.enderecosDigitais.map(x => (
                <React.Fragment>
                  <Row>
                    <Col span={10} className="campos-nome">E-mail/Site:</Col>
                    <Col span={12}><Input value={x.url} /></Col>
                  </Row>
                </React.Fragment>
              ))}
            </Col>
            <Col span={7}></Col>
          </Row>
        )}
        <Row>
          <Col span={8}></Col>
          <Col span={8}>
            <Row>
              <Col span={24}>
                <div className="botao">
                  <Link to="/">
                    <Button type="primary" shape="round" block>INÍCIO</Button>
                  </Link>
                </div>
              </Col>
            </Row>          
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({cadastro: state.clienteReducer.cadastro})

const mapDispatchToProps = (dispatch) => {
  return {
    consultarCadastro: (token) => dispatch(consultarCadastro(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cadastro);
